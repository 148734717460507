import * as React from 'react';
import * as _ from 'lodash';
import { SettlementAgreementStatus, settlementAgreementStatusTitle } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';
import { ClientsFilterOptions, SettlementAccountExistType, SettlementAccountExistTypeTitle } from './ClientsFilterOptions';
import { UnrequestedAgreementStatus } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';
import { FilterPanel } from '../../../filters/FilterPanel';
import { FilterClear } from '../../../filters/FilterClear';
import { FilterButton } from '../../../filters/FilterButton';
import { FilterSelect } from '../../../common/filters/FilterSelect';
import { useEffect } from 'react';
import { isEqual } from 'lodash';

export const defaultClientsFilter = {
    settlementAccountExist: [],
    agreementStatus: []
};

interface Props {
    disabled: boolean;
    onChange: (filter: ClientsFilterOptions) => void;
    currentFilter: ClientsFilterOptions;
}

export function ClientsFilter({ disabled, onChange, currentFilter }: Props) {
    const [lastAppliedFilter, setLastAppliedFilter] = React.useState<ClientsFilterOptions>({ ...currentFilter });
    const [filter, setFilter] = React.useState<ClientsFilterOptions>({ ...currentFilter });

    useEffect(() => {
        if (isEqual(currentFilter, defaultClientsFilter)) {
            setFilter({ ...defaultClientsFilter });
            setLastAppliedFilter({ ...defaultClientsFilter });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter]);

    const selectSettlementAccountExistOption = (type: SettlementAccountExistType) => {
        setFilter({ ...filter, settlementAccountExist: [...filter.settlementAccountExist, type] })
    };

    const unselectSettlementAccountExistOption = (type: SettlementAccountExistType) => {
        setFilter({
            ...filter,
            settlementAccountExist: filter.settlementAccountExist.filter(x => x !== type)
        })
    };

    const selectAgeementStatus = (status: SettlementAgreementStatus | UnrequestedAgreementStatus) => {
        setFilter({ ...filter, agreementStatus: [...filter.agreementStatus, status] })
    }

    const unselectAgreementStatus = (status: SettlementAgreementStatus | UnrequestedAgreementStatus) => {
        setFilter({
            ...filter,
            agreementStatus: filter.agreementStatus.filter(x => x !== status)
        })
    };

    const handleClear = () => {
        const filter = { ...defaultClientsFilter };
        setFilter(filter);
        setLastAppliedFilter({ ...defaultClientsFilter })
        onChange(filter);
    };

    const handleApply = () => {
        setLastAppliedFilter({ ...filter });
        onChange(filter);
    };

    return (
        <FilterPanel>
            <FilterSelect
                title="Settlement Accounts Registered"
                multiply={true}
                isApplied={lastAppliedFilter.settlementAccountExist.length === filter.settlementAccountExist.length}
                options={[
                    SettlementAccountExistType.WithoutSettlementAccount,
                    SettlementAccountExistType.WithSettlementAccount
                ].map(x => ({
                    text: SettlementAccountExistTypeTitle[x],
                    value: x,
                    selected: filter.settlementAccountExist.some(e => e === x),
                    visible: true,
                    disabled: false
                }))
                }
                onChangeItemSelection={(item, checked) => checked
                    ? selectSettlementAccountExistOption(item.value)
                    : unselectSettlementAccountExistOption(item.value)
                }
                onClearAll={() => setFilter({ ...filter, settlementAccountExist: [] })}
                onSelectAll={() => setFilter({
                    ...filter,
                    settlementAccountExist: [
                        SettlementAccountExistType.WithoutSettlementAccount,
                        SettlementAccountExistType.WithSettlementAccount
                    ]
                })}
            />
            <FilterSelect
                title="Request Status"
                multiply={true}
                isApplied={lastAppliedFilter.agreementStatus.length === filter.agreementStatus.length}
                options={[
                    SettlementAgreementStatus.pending,
                    SettlementAgreementStatus.confirmed,
                    SettlementAgreementStatus.rejected,
                    UnrequestedAgreementStatus.Unrequested
                ].map(x => ({
                    text: x === UnrequestedAgreementStatus.Unrequested ? 'Unrequested' : settlementAgreementStatusTitle[x],
                    value: x,
                    selected: filter.agreementStatus.some(e => e === x),
                    visible: true,
                    disabled: false
                }))
                }
                onChangeItemSelection={(item, checked) => checked
                    ? selectAgeementStatus(item.value)
                    : unselectAgreementStatus(item.value)
                }
                onClearAll={() => setFilter({ ...filter, agreementStatus: [] })}
                onSelectAll={() => setFilter({
                    ...filter,
                    agreementStatus: [
                        SettlementAgreementStatus.pending,
                        SettlementAgreementStatus.confirmed,
                        SettlementAgreementStatus.rejected,
                        UnrequestedAgreementStatus.Unrequested
                    ]
                })}

            />
            <FilterButton caption="Apply" disabled={_.isEqual(lastAppliedFilter, filter) || disabled} onClick={handleApply} />
            <FilterClear isShown={!_.isEqual(defaultClientsFilter, filter)} isDisabled={disabled} onClick={handleClear} />
        </FilterPanel>
    );
}
