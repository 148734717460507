import * as React from 'react';
import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
    title?: string;
    text?: ReactNode;
    children?: ReactNode;
    textView?: boolean;
    textViewRow?: boolean;
    className?: string;
    onResetClick?: () => void;
}

export const EmptyPlaceholder = ({
    title = '',
    text = 'No search results found.',
    children = null,
    textView = false,
    textViewRow = false,
    className,
    onResetClick,
}: Props) => (
    <div className={cn('empty-placeholder', className, { 'text-view': textView, 'text-view-row': textViewRow })}>
        {!!title && <h2>{title}</h2>}
        {!!text && (
            <p>
                {text}
                {onResetClick && (
                    <span className="placeholder-text">
                        {" "} <button className="btn-link btn-link-lg regular secondary" onClick={onResetClick}>Click here</button> to clear the filters/search for more results.
                    </span>
                )}
            </p>
        )}
        {children}
    </div>
);
