import { OriginatingTransactionDocument } from "../../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { EmptyPlaceholder } from "../../../../common";
import { DownloadedDocument } from "../../../../../types/amr-pipeline/models/AnalyticsSession";
import DownloadDocument from "../../../../amrPipeline/common/documents/DownloadDocument";
import useDownloadDocumentList from "../../../../../effects/useDownloadDocumentList";
import { cloManagersService } from "../../../../../services/clo-managers.service";
import { useMemo } from "react";
import { getUniqueDocsWithDownloadsCount } from "../../../../../utils/analytics.utils";

interface Props {
    companyReferenceName: string;
    documents: DownloadedDocument[];
}

export function PresDownloadedTab({ companyReferenceName, documents }: Props) {
    const uniqueDocuments = useMemo(() => getUniqueDocsWithDownloadsCount(documents), [documents]);

    const { loadHandler, loadingState } = useDownloadDocumentList({
        documents: uniqueDocuments,
        downloadRequest: (referenceName: string) =>
            cloManagersService.downloadManagerPresentationFile(companyReferenceName, referenceName),
    });

    const getDocumentDownloadLink = (document: DownloadedDocument) =>
        loadHandler(document.referenceName, document.name);

    if (!uniqueDocuments.length) {
        return <EmptyPlaceholder text="There is no information to display." />;
    }

    return (
        <ul className="list-bullet docs-downloaded-box">
            {uniqueDocuments.map(d => (
                <li key={d.referenceName} className="download-document-item">
                    {d.isDeleted ? (
                        d.name
                    ) : (
                        <>
                            <DownloadDocument
                                document={
                                    {
                                        referenceName: d.referenceName,
                                        name: d.name,
                                    } as OriginatingTransactionDocument
                                }
                                onClick={() => getDocumentDownloadLink(d)}
                                isLoading={loadingState[d.referenceName]}
                                loadingText
                                secondary
                            >
                                {d.name}
                            </DownloadDocument>
                            {d.downloadCount > 1 && !loadingState[d.referenceName] && ` — ${d.downloadCount} downloads`}
                        </>
                    )}
                </li>
            ))}
        </ul>
    );
}
